// import 'jquery/dist/jquery.js';
// import $ from 'jquery';
// global.$ = global.jQuery = $;

// Import main stylesheet:
import './scss/bundle.scss';

// - OR- Import editor stylesheet:
// (DON'T RUN BOTH. COMMENT OUT ONE OR THE OTHER AT A TIME.)
// (SEE ALSO webpack config)
// import './scss/custom/_editor.scss';

// - OR- Import login stylesheet:
// (DON'T RUN BOTH. COMMENT OUT ONE OR THE OTHER AT A TIME.)
// (SEE ALSO webpack config)
// import './scss/custom/_login.scss';

// Import SVGs dropped into the /src/svg/ directory
// to compile into one SVG symbol file:
function requireAllSVGs(r) { r.keys().forEach(r); }
requireAllSVGs(require.context('../src/svg/', true, /\.svg$/));

// Import images dropped into the /src/img/ directory
function requireAllImgs(r) { r.keys().forEach(r); }
requireAllImgs(require.context('../src/img/', true, /\.(jpg|jpeg|gif|png|svg|webp)$/));

// popper.js required by Bootstrap:
import 'popper.js/dist/umd/popper-utils.js';
import 'popper.js/dist/umd/popper.js';

// Bootstrap (comment out unneeded components):
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';

// Plugins:
// Slick Slider: http://kenwheeler.github.io/slick/
import 'slick-carousel/slick/slick.js';
// MatchHeight: https://brm.io/jquery-match-height-demo/
// import 'jquery-match-height/dist/jquery.matchHeight.js';
import { CountUp } from 'countup.js/dist/countUp.js'; 		// Animated Counters: https://inorganik.github.io/countUp.js/
window.CountUp = CountUp;
import { throttle } from 'lodash';

// WebMechanix's JS:
import './js/main.js';
